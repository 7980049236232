<template>
	<div class="contain">
		<div class="all">
			<div class="footerWhite">
				<div class="footerWhiteMid">
					<div class="block1">
						<div>
							<div class="fuwu">客户服务</div>
							<div class="contact">
								<div class="littleBlock">
									<div class="littleBlock1">
										<img src="../img/zaixiankefu.png" />
									</div>
									<div class="kefu">在线客服</div>
								</div>
								<div class="littleBlock" @click="feedBack">
									<div class="littleBlock1">
										<img src="../img/wentifankui.png" />
									</div>
									<div class="kefu">问题反馈</div>
								</div>
							</div>
						</div>
					</div>
					<div class="block1">
						<div>
							<div class="fuwu">扫描下载APP</div>
							<div class="erweima flex jc-center ai-center">
								<img src="../img/erweima.png" />
							</div>
						</div>
					</div>
					<div class="block1">
						<div class="footerBlack">
							<div class="blockRight fuwu">服务热线</div>
							<div class="blockRight blockRight1">020-31802914</div>
							<div class="blockRight blockRight2">周一至周日 8:00-18:00</div>
							<div class="blockRight blockRight2">（仅收市话费）</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footerBelow">
				<div class="footerTop">
					<div class="Cmd">
						<div class="cBlock">
							<el-row>
								<el-col :span="6">
									<div class="logo">
										<img src="../img/1.png" />
									</div>
								</el-col>
								<el-col :span="16" :offset="2">
									<div class="titleWords">商家服务</div>
									<div>
										<span class="spanWords1">供应商入驻</span>
										<span>诊断维修专家入驻</span>
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="cBlock">
							<el-row>
								<el-col :span="6">
									<div class="logo">
										<img src="../img/2.png" />
									</div>
								</el-col>
								<el-col :span="16" :offset="2">
									<div class="titleWords">交易方式</div>
									<div>
										<span class="spanWords1">担保交易</span>
										<span class="spanWords2">线下汇款</span>
										<span>分期支付</span>
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="cBlock">
							<el-row>
								<el-col :span="6">
									<div class="logo">
										<img src="../img/3.png" />
									</div>
								</el-col>
								<el-col :span="16" :offset="2">
									<div class="titleWords">平台服务</div>
									<div>
										<span class="spanWords1">交易维权</span>
										<span>帮助中心</span>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</div>
				<div class="footerBottom">
					<div class="Cmd">
						<div class="footer1">
							<span>关于我们</span>
							<span>帮助中心</span>
						</div>
						<!-- <div class="footer2">
							<div class="aboutUs">友情链接1</div>
							<div class="aboutUs">友情链接2</div>
							<div class="aboutUs">友情链接3</div>
							<div class="aboutUs">友情链接4</div>
							<div class="aboutUs">友情链接5</div>
							<div class="aboutUs">友情链接6</div>
							<div class="aboutUs">友情链接7</div>
							<div class="aboutUs">友情链接8</div>
							<div class="aboutUs">友情链接9</div>
						</div> -->
						<div class="footer3">
							<a href="https://beian.miit.gov.cn" target="_blank">© 2021 smheee.com 长材智能运维-圣名远扬, All Rights Reserved.Email : shengming_tech@163.com Tel: 020-31802914 粤ICP备2021034595号-1 </a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 问题反馈弹窗 -->
		<el-dialog title="问题反馈" :visible.sync="feedbackDia">
			<el-form :model="feedbackForm">
				<el-form-item label="反馈类型" :label-width="formLabelWidth">
					<div class="feedback">
						<el-select v-model="feedbackForm.type" placeholder="请选择">
							<el-option v-for="item in enumList" :key="item.type" :label="item.title" :value="item.type">
							</el-option>
						</el-select>
					</div>
				</el-form-item>
				<el-form-item label="反馈内容" :label-width="formLabelWidth">
					<el-input type="textarea" :rows="5" placeholder="对我们网站、商品、服务，您有什么建议吗？你还希望我们为您提供哪些商品？请告诉我们..."
						v-model="feedbackForm.content">
					</el-input>
				</el-form-item>
				<el-form-item label="相关图片" :label-width="formLabelWidth">
					<el-upload class="avatar-uploader mr-10" :action="baseURL + '/base-api/file/upload'"
						:show-file-list="false" :on-success="handleAvatarSuccess" :on-change="handleChange">
						<img v-if="feedbackForm.image" :src="feedbackForm.image" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="手机号码" :label-width="formLabelWidth">
					<el-input placeholder="请输入手机号码" v-model="feedbackForm.mobile">
					</el-input>
				</el-form-item>
				<el-form-item label="验证码" :label-width="formLabelWidth">
					<el-input placeholder="请输入图形验证码" v-model="feedbackForm.smsCode">
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="feedbackDia = false">取 消</el-button>
				<el-button type="primary" @click="save">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ImageUrl: "",
				feedbackDia: false,
				enumList: [],
				fileList: [],
				feedbackForm: {
					content: "",
					type: "",
					smsCode: "",
					image: "",
					mobile: "",
				},
				formLabelWidth: "120px",
			};
		},
		methods: {
			//   问题反馈弹框
			feedBack() {
				this.feedbackDia = true;
				this.$get("/base-api/feedback/enum").then((data) => {
					this.enumList = data.data;
				});
			},
			// 保存
			save() {
				this.$postJson('/base-api/feedback/save', this.feedbackForm).then(data => {
					if (data.code == 1000) {
						this.$message.success('保存成功')
						this.feedbackDia = false
						this.feedbackForm = {}
					} else {
						this.$message.info(data.msg)
					}
				})
			},
			handleAvatarSuccess(file) {
				this.feedbackForm.image = this.photoURL + file.data;
			},
			handleChange(file) {
				this.fileList.push(file.uid);
			},
		},
	};
</script>

<style scoped lang="scss">
	.all {
		background-color: white;

		.footerWhite {
			.footerWhiteMid {
				width: 1440px;
				margin: 0 auto 20px;
				display: flex;

				.block1 {
					width: 300px;
					height: 230px;
					display: flex;
					justify-content: center;
					margin: 20px 0;
					flex: 1;
					border-right: 1px solid #a4a9b0;

					&:last-of-type {
						border-right: none;
					}

					.fuwu {
						margin: 15px 0;
						text-align: center;
						font-size: 32px;
					}

					.erweima {
						width: 157px;
						height: 157px;
						margin: 0 auto;
						background-color: white;

						img {
							width: 147px;
							height: 147px;
						}
					}

					.contact {
						display: flex;
						justify-content: space-around;

						.littleBlock {
							width: 110px;
							height: 110px;
							margin-right: 10px;
							cursor: pointer;
							border-radius: 8px;
							border: 1px solid #a4a9b0;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;

							.littleBlock1 {
								margin-bottom: 8px;

								img {
									width: 44px;
									height: 44px;
								}
							}

							.kefu {
								font-size: 16px;
								color: #333333;
							}
						}
					}

					.footerBlack {
						text-align: center;

						.blockRight {}

						.blockRight1 {}

						.blockRight2 {
							letter-spacing: 1px;
							color: #999999;
							font-size: 14px;
							margin: 20px 0;
						}
					}
				}
			}
		}

		.footerBelow {
			color: white;
			background-color: #191919;

			.footerTop {
				width: 1440px;
				height: 150px;
				margin: 0 auto;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #8d8d8d;

				.Cmd {
					margin: 0 auto;
					width: 1100px;
					display: flex;
					justify-content: space-around;

					.cBlock {
						font-size: 12px;
						width: 300px;

						.logo {
							width: 80px;
							height: 80px;

							img {
								width: 100%;
								height: 100%;
							}
						}

						.titleWords {
							font-size: 24px !important;
							font-weight: 400;
							// font-family: "Arial Normal", "Arial";
							margin-bottom: 15px;
						}

						.spanWords1 {
							padding-right: 10px;
						}

						.spanWords2 {
							padding-right: 10px;
						}
					}
				}
			}

			.footerBottom {
				width: 1440px;
				margin: 0 auto;

				.Cmd {
					margin: 0 auto;
					width: 900px;
					font-size: 13px;
					color: #8d8d8d;

					.footer1 {
						margin: 12px auto 8px;
						height: 36px;
						text-align: center;

						span {
							padding: 0px 8px;
						}
					}

					.footer2 {
						display: flex;
						justify-content: space-between;

						.aboutUs {
							height: 36px;
							width: 80px;
							text-align: center;
							position: relative;

							&::after {
								position: absolute;
								right: -10px;
								bottom: 20px;
								display: inline-block;
								content: " ";
								width: 1px;
								height: 10px;
								background-color: #8d8d8d;
							}
						}
					}

					.footer3 {
						height: 36px;
						margin: 0 auto;
						text-align: center;
					}
				}
			}
		}
	}

	// 组件的样式
	::v-deep .feedback {
		.el-select {
			width: 100%;
		}
	}

	::v-deep .el-dialog__footer {
		text-align: center;
	}

	::v-deep .el-dialog__header {
		background-color: #f8f8f8;
	}

	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 88px;
		height: 88px;
		line-height: 88px;
		text-align: center;
	}

	.avatar {
		width: 88px;
		height: 88px;
		display: block;
	}
</style>
