<template>
	<div>
		<header class="os-header fs-12 h-40">
			<div class="w-1440 flex jc-between">
				<div class="lightColor w-600">
					<el-carousel height="40px" direction="vertical" :interval="3000" :autoplay="true" indicator-position="none">
						<el-carousel-item v-for="item in tips" :key="item">
							<p class="tips-txt">{{ item }}</p>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="flex ai-center jc-center">
					<el-dropdown v-if="userName">
						<span class="el-dropdown-link">
							{{ userName }}
							<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item><span @click="toOrderDetails">我的订单</span></el-dropdown-item>
							<!-- <el-dropdown-item>收藏商品</el-dropdown-item> -->
							<el-dropdown-item>安全设置</el-dropdown-item>
							<el-dropdown-item><span @click="handleLogout">退出登录</span></el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<!-- <p v-if="userName">{{userName}}</p> -->
					<div v-else>
						<span class="blueColor pr-10 cursor"><router-link to="/login">请登录</router-link></span>
						<el-dropdown>
							<span class="el-dropdown-link fs-12">
								请注册
								<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item><router-link to="/register">普通用户登录注册</router-link></el-dropdown-item>
								<el-dropdown-item><router-link to="/registerZJ">诊断维修专家登录注册</router-link></el-dropdown-item>
								<el-dropdown-item><router-link to="/registerGYS">供应商注册</router-link></el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<div class="flex ai-center">
						<i class="ml-15 el-icon-mobile-phone"></i>
						<p>手机版</p>
					</div>
				</div>
			</div>
		</header>
		<nav class="pos-rel">
			<div class="os-nav ai-center jc-between flex">
				<div class="flex ai-center">
					<div class="logo"><img src="../img/slogan.png" alt="行业生态一站式服务" width="264" height="48" @click="openMall()" /></div>
					<div class="ml-50 tabs flex">
						<ul class="menuClass">
							<li
								class="menuClass_menuItem"
								:class="{ 'is-active': index == activeMenu }"
								v-for="(item, index) in menuList"
								:key="index"
								@click.stop="routerJump(item.path, index)"
								@mouseenter="getLeft"
							>
								{{ item.name }}
								<div class="menuClass_menuItem_ul" v-if="item.childen.length > 0">
									<div class="menuClass_menuItem_ulbox">
										<div class="cursor boxs" v-for="item1 in item.childen" :key="item1.index" @click.stop="routerJump2(item1,index)">
											<img class="item_img" v-if="item1.image" :src="photoURL + item1.image" alt="" />
											<div class="item_name">{{ item1.name }}</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="flex">
					<el-input class="searchradiu" placeholder="输入关键词" size="medium" v-model="keyword"></el-input>
					<el-button class="search searchradius" type="primary" slot="append" @click="search">搜索</el-button>
					<!-- <div class="flex ai-center text-right ml-40">
						<i class="fs-18 el-icon-shopping-cart-full"></i>
						<span class="fs-16 cart">购物车</span>
					</div> -->
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
import caches from '@cache/cache.js';
import Bus from '@/bus/index.js';
export default {
	data() {
		return {
			userName: '',
			activeMenu: 0, //tab下标
			liLeft: 0,
			keyword: '', //搜索词
			// 跑马灯tips
			tips: ['专业的诊断维修服务，为你排优解难', '全国首家从设备监测、设备销售、租赁、诊断维修服务平台', '生产设备专业的电商采购平台', '一站式租赁服务，为您减轻资金积压'],
			menuList: [
				{
					name: '电商采购',
					class: 'pl-466',
					path: '/shopping',
					childen: []
				},
				{
					name: '设备租赁',
					class: 'pl-466',
					path: '/lease',
					childen: []
				},
				{
					name: '诊断维修',
					class: 'pl-466',
					path: '/diagnosis',
					childen: [
						{
							name: '诊断专家',
							path: '/diagnosisExpert',
							id: 1,
							type:2
						},
						{
							name: '维修师傅',
							path: '/diagnosisExpert',
							id: 2,
							type:2
						}
					]
				}
			]
		};
	},
	//默认刷新跳回第一页
	beforeCreate() {
		// this.$router.push('/shopping');
	},
	mounted() {
		this.getuserInfo();
	},
	created() {
		this.Bus.$on('headerSearch2', param => {
			if(param.param){
				this.keyword = param.param;
			}
			this.activeMenu = param.activeMenu;
		});
	},
	methods: {
		// 登录成功菜单栏显示
		getuserInfo() {
			if (caches.token.get()) {
				this.$get('/user-api/user-base/userInfo', {
					Headers: caches.token.get()
				}).then(data => {
					if (data.data) {
						this.userName = data.data.name;
					}
				});
			}
			this.$get('/shop-api/mall/goods/category').then(data => {
				if (data.code != 1000) {
					return;
				}
				this.menuList[0].childen = data.data;
				this.menuList[0].childen.forEach(item=>{
					item.type=0
				})
			});
			this.$get('/shop-api/mall/rent/category').then(data => {
				if (data.code != 1000) {
					return;
				}
				this.menuList[1].childen = data.data;
				this.menuList[1].childen.forEach(item=>{
					item.type=1
				})
			});
		},
		// 退出
		handleLogout() {
			this.$get('user-api/auth/logout', {})
				.then(res => {
					this.userName = '';
					caches.clearAll();
					this.$router.push('/index');
					this.$message({
						message: '登出成功',
						type: 'success'
					});
				})
				.catch(err => {
					this.$message.error(err);
				});
		},
		// path 代表跳转路径， query是参数
		routerJump(path, index, query) {
			if (!path) {
				return;
			}
			this.$router.push({
				path,
				query: {
					id: query
				}
			});
			this.activeMenu = index;
		},
		routerJump2(item, index) {
			let url =null;
			switch (item.type) {
				case 0:
					url = 'shoppingList';
					break;
				case 1:
					url = 'leaseList';
					break;
				case 2:
					url = 'diagnosisExpert';
					break;
			}
			this.$router.push({
				name: url,
				query: {
					id: item.id
				}
			});
			this.activeMenu = index;
		},
		
		thoseMenu(index) {
			this.activeMenu = index;
		},
		// 搜索
		search() {
			if(!this.keyword){
				this.$message.error('搜索不能为空');
				return
			}
			let url = null;
			switch (this.activeMenu) {
				case 0:
					url = 'shoppingList';
					break;
				case 1:
					url = 'leaseList';
					break;
				case 2:
					url = 'diagnosisExpert';
					break;
			}
			this.$router.push({
				name: url,
				query: {
					search: this.keyword
				}
			});
		},
		// 我的订单
		toOrderDetails() {
			this.$router.push({
				path: '/purchaseOrder'
			});
		},
		getLeft(event) {
			this.liLeft = event.screenX - event.offsetX;
		},
		openMall() {
			this.$router.push({
				path: '/shopping'
			});
		}
	},
	destroyed() {}
};
</script>

<style lang="scss" scoped>
$text-base-color: #333;

::v-depp .el-carousel__indicators--vertical {
	display: none;
}

.cursor {
	cursor: default;
}

::v-deep .el-tabs__nav-wrap::after {
	display: none !important;
}

.os-header {
	color: $text-base-color;
	background: #efeff4;
}

.ml-266 {
	margin-left: 266px;
}

.ml-50 {
	margin-left: 50px;
}

.pl-466 {
	padding-left: 466px;
}

.h-40 {
	height: 40px;
}

.w-600 {
	min-width: 600px;

	.tips-txt {
		height: 40px;
		line-height: 40px;
		color: $text-base-color;
	}
}

.mr-256 {
	margin-right: 256px;
}

.os-nav {
	height: 108px;
	background: white;
	z-index: 9;
	width: 1440px;
	margin: 0 auto;
}

.search {
	background-color: #4a99f6 !important;
	color: #fff !important;
	height: 36px;
	margin-left: -2px;
	z-index: 66;
}

.searchradiu {
	border-radius: 5px 0 0 5px !important;
}

.searchradius {
	border-radius: 0 5px 5px 0 !important;
}

.el-icon-shopping-cart-full {
	font-weight: bold;
	color: #4a99f6;
}

.cart {
	min-width: 60px;
	color: #888;
}

.sub-nav {
	position: absolute;
	left: 0;
	bottom: -100px;
	height: 100px;

	> div {
		background-color: #4a99f6;
		height: 100px;
	}

	.white {
		color: #fff;
	}
}

.menuClass {
	height: 48px;
	line-height: 48px;
	list-style: none;
	color: #262626;
	.menuClass_menuItem {
		cursor: pointer;
	}
	&_menuItem {
		cursor: default;
		float: left;
		width: 120px;
		text-align: center;
		font-size: 16px;

		&_ul {
			display: none;
			position: absolute;
			width: 100%;
			left: 0;
			color: #262626;
			background-color: #74b7fe;
			list-style: none;

			li {
				float: left;
				color: white;
				width: 80px;

				&:hover {
					color: #efeff4;
				}
			}
		}

		&:hover {
			.menuClass_menuItem_ul {
				z-index: 999;
				display: block;

				.menuClass_menuItem_ulbox {
					width: 1440px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					margin: 0 auto;
					.boxs {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						padding: 0 30px;
						cursor: pointer;
						height: 100px;
						&:hover {
							background-color: #409eff;
						}
						.item_img {
							margin-top: 10px;
							width: 60px;
							height: 60px;
							border-radius: 5px;
						}
						.item_name {
							font-size: 16px;
							font-weight: 500;
							line-height: 30px;
							color: #ffffff;
						}
					}
				}
			}
		}
	}

	.is-active {
		color: #409eff;
	}
}

.w-1440 {
	width: 1440px;
	margin: 0 auto;
}

.text-right {
	text-align: right;
}

.ml-40 {
	margin-left: 40px;
}

.el-dropdown-link {
	cursor: default;
	// color: #409eff;
}

.el-icon-arrow-down {
	font-size: 12px;
}
</style>
