<template>
	<div>
		<Header @handleSelect='handleSelect'></Header>
		<router-view />
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from "./component/header.vue";
	import Footer from "./component/footer.vue";
	export default {
		data() {
			return {}
		},
		components: {
			Header,
			Footer,
		},
		// computed: {
		// 	isDiagnosisLogined() {
		// 		return this.$store.state.isDiagnosisLogined;
		// 	}
		// },
		// watch: {
		// 	isDiagnosisLogined(val) {
		// 			console.log(val);
		// 		if (!val) {
		// 			this.isShow = true;
		// 		}
		// 	}
		// },
		methods: {
			handleSelect(val) {
				console.log(val, 'val');
				// this.$router.push({
				// 	name: val
				// })
			}
		}
	};
</script>
